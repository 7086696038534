@import "../../resources/scss/settings";

.mobile-footer-menu-wrapper {
  font-size: 12px;
  grid-column: 1 / -1;
  margin: 40px 0;
  position: relative;
  margin-bottom: 10px;

  &:before {
    position: absolute;
    top: -20px;
    width: calc(100% - 40px);
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: map_get($foundation-palette, secondary-alt);
    content: "\00a0";
  }

  ul {
    li {
      display: inline-block;

      a {
        color: map_get($foundation-palette, secondary-alt);
        padding: 0 10px;
      }

      &:nth-child(1) a {
        padding-left: 0;
      }
      &:nth-last-child(1) a {
        padding-right: 0;
      }
    }
  }
}