@use "../../../breakpoints" as *;

.latest-news-wrapper {
  ul {
    li {
      &:not(:nth-last-child(1)) {
        margin-bottom: 20px;
      }
    }
  }
  @include breakpoint(medium down) {
    margin-bottom: 25px;
  }
}