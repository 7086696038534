@import "../../resources/scss/settings";

.page-loader {
  position: fixed;
  display: grid;
  justify-items: center;
  align-items: center;
  z-index: 10001;
  background: lighten(map_get($foundation-palette, tertiary), 10%);
  width: 100vw;
  height: 100vh;
  transition: opacity 500ms ease;
  top: 0;
  left: 0;

  &.done {
    opacity: 0;
    pointer-events: none;
  }
}