@import "settings";

@mixin ios-safari-only() {
  @supports (-webkit-touch-callout: none) {
    @content
  }
}

@mixin squde-loading-content {
  &.is-loading {
    position: relative;
    overflow: hidden;

    &:hover {
      cursor: progress;
      text-decoration: none !important;
    }

    &:before {
      content: "\00a0";
      z-index: 9000;
      position: absolute;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 100%);
      width: 100%;
      height: 100%;
      animation-name: loading-content-animation;
      animation-duration: 1.5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    .image-wrapper {
      background: $light-gray;
    }

    @keyframes loading-content-animation {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(100%);
      }
    }
  }
}