@use "../../breakpoints" as *;

.footer-socials-wrapper {
  padding: 0 !important;
  display: grid;
  margin-top: 20%;

  @include breakpoint(medium) {
    grid-row: 2 / 3;
    grid-column: 9 / -1;
    text-align: left;
    transform: translateX(10%);
    margin-top: 0;

    @for $i from 1 through 10 {
      &[data-contains="#{$i}"] {
        grid-template-columns: repeat(#{$i}, 1fr);
      }
    }
  }
  @include breakpoint(large) {
    grid-column: 10 / -1;
    transform: translateX(-8%);
  }

  a {
    //margin: 0 10px;
    display: inline-grid;
    height: 50px;
    //width: 50px;
    align-items: center;
    justify-items: center;

    &:nth-child(1) {
      margin-left: 0;
    }

    @include breakpoint(medium) {
      &.whatsapp {
        display: none;
      }
    }
  }
}