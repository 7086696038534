@import "../../../resources/scss/settings";

@mixin eps-quote() {
  .wp-block-quote, .wp-block-quote * {
    color: map_get($foundation-palette, secondary-alt);
    margin: 0;
    padding: 0;
    font-size: calc(max(16px, calc(100vw / 1920 * 48)));
    line-height: inherit;
  }
  .wp-block-quote {
    justify-self: center;
    align-self: center;
  }
}