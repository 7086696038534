@use "../../breakpoints" as *;
@import "../../../resources/scss/settings";

@mixin eps-headings() {
  .wp-block-squde-clean-eps-eps-title {
    padding: 0 map_get($visible-grid-size, small);
    color: map_get($foundation-palette, primary);

    &:not(.is-style-small-title) {
      font-weight: 500;
    }

    &.is-style-default-dark {
      color: #000;
      font-size: 40px;
      margin: 20px 0;

      @include breakpoint(small only) {
        padding: 0;
      }
    }

    &.is-style-small-title {
      color: map_get($foundation-palette, secondary-alt);
      text-transform: uppercase;

      @include breakpoint(small) {
        font-size: 18px;
      }
    }


    @include breakpoint(medium) {
      font-size: calc(100vw / 1920 * 48);
      padding: 0 calc(100vw / 1920 * 50);
      margin: calc(100vw / 1920 * 50) 0;

      &.is-style-small-title {
        font-size: calc(100vw / 1920 * 24);
      }
    }


    .heading-icon {
      margin-top: -35px !important;
      transform: translateY(25%);

      &.for-h1 {
        background-color: map_get($foundation-palette, primary);
      }
      &.for-h2 {
        width: 45px;
        height: 45px;
        margin-right: 20px;
        background-color: map_get($foundation-palette, secondary-alt);
      }
    }
  }

  .wp-block-group, > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-2):not(.is-style-columns-3):not(.is-style-columns-4) {
    .wp-block-squde-clean-eps-eps-title {
      @include breakpoint(medium) {
        padding: 0;
      }
    }
  }
}
