@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.footer-wrapper {
  position: relative;
  display: grid;

  @each $screenSize, $gridSize in $visible-grid-size {
    @if ($screenSize == 'full') {
      @media screen and (min-width: $global-width) {
        grid-template-columns: 1fr repeat(10, #{$gridSize}) 1fr;
      }
    } @else {
      @include breakpoint($screenSize only) {
        @if ($screenSize == 'small') {
          grid-template-columns: 1fr 100px;
        } @else {
          grid-template-columns: 1fr repeat(10, #{$gridSize}) 1fr;
        }
      }
    }
  }

  > *:not(.logo-wrapper) {
    padding: 0 20px;
  }
  .logo-wrapper {
    display: none;

    @include breakpoint(large) {
      display: inherit;
      grid-column: 2 / span 4;
    }
  }
  .latest-news-wrapper {
    @include breakpoint(medium) {
      grid-column: span 5;
    }
    @include breakpoint(large) {
      grid-column: span 2;
    }
  }
  .footer-menu-wrapper {
    @include breakpoint(medium) {
      grid-column: span 7;
    }
    @include breakpoint(large) {
      grid-column: span 4;
    }
  }

  h4 {
    color: map_get($foundation-palette, secondary-alt);
    font-size: 18px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        text-decoration: none;

        p {
          margin: 0;
          font-size: 12px;

          @include breakpoint(medium) {
            font-size: 14px;
          }
        }
      }
    }
  }
}