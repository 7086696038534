@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700;1,800&display=swap');

@import "resources/scss/text";
@import "resources/scss/foundation";
@import "resources/scss/absolute";
@import "resources/scss/button";



body {
  margin: 0;
  font-family: "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: map_get($foundation-palette, primary);
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

input {
  border-radius: 0;
}

:root {
  --color-primary:  #029891;
  --color-primary-alt: #FFEC85;
  --color-secondary:  #EF7F22;
  --color-secondary-alt:  #60A8D8;
  --color-secondary-alt-light:  #8ADAE3;
  --color-tertiary:  #D2D1E7;
  --color-success:  #BBF084;
  --color-warning:  #ffae00;
  --color-alert:  #EF4742;

  /// Color used for light gray UI items.
  /// @type Color
  --color-light-gray: #e6e6e6;

  /// Color used for medium gray UI items.
  /// @type Color
  --color-medium-gray: #cacaca;

  /// Color used for dark gray UI items.
  /// @type Color
  --color-dark-gray: #666666;

  /// Color used for black ui items.
  /// @type Color
  --color-black: #0B0F41;
  --color-real-black: #333333;

  /// Color used for white ui items.
  /// @type Color
  --color-white: #fefefe;

  /// Background color of the body.
  /// @type Color
  --color-body-background: var(--color-white);

  /// Text color of the body.
  /// @type Color
  --color-body-font-color: var(--color-black);
}
