@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.squde-icon {
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
  display: inline-block;

  &.white {
    background-color: $white;
  }
  &.black {
    background-color: $black;
  }
  @each $type, $color in $foundation-palette {
    &.#{$type} {
      &:not(.hollow) {
        background-color: $color;
      }
    }
  }

  @include breakpoint(small) {
    transform: scale(.75);
    //transform-origin: top center;
  }
}