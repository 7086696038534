@import "../../resources/scss/settings";

.checkbox-input-wrapper {
  display: grid;
  grid-template-columns: 50px 1fr;

  .checkbox-wrapper {
    input {
      display: none;
      & + label {
        display: inline-block;
        height: 20px;
        width: 20px;
        border: solid 2px #707070;
        background: white;
        transition: all 250ms ease;
        border-radius: 50%;

        &:before {
          content: "\00a0";
        }
      }
      &:checked + label {
        border-color: white;
        background: map_get($foundation-palette, secondary);
      }
    }
  }
  .ui-wrapper {
    label {
      font-size: 14px;
      color: $real-black;
    }
  }
}