.submission-form-wrapper {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 100%);
  transition: transform 1s ease;

  > * {
    transition: all 500ms ease;
    clip-path: inset(0 0 0 0);

    &:nth-child(1) {
      clip-path: inset(0 0 0 -50px);
    }
  }
  &:not(.loading) {
    .submission-form-loading {
      clip-path: inset(0 100% 0 0);
    }
  }
  &:not(.done) {
    .submission-form-done {
      clip-path: inset(0 100% 0 0);
    }
  }

  &.loading {
    transform: translateX(-100%);

    .submission-form {
      clip-path: inset(0 0 0 100%);
    }
  }
  &.done {
    transform: translateX(-200%);

    .submission-form, .submission-form-loading {
      clip-path: inset(0 0 0 100%);
    }
  }
}