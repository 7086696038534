@import "settings";

$palette: map_merge($foundation-palette, (
        primary: #B8EA84,
        secondary: #60A8D8,
        warning: #F46E1D,
        original-secondary: map_get($foundation-palette, secondary)
));

.button {
  line-height: 30px;
  padding: 10px 70px;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: inherit;

  @include breakpoint(medium) {
    padding: 10px 20px;
  }
  @include breakpoint(large) {
    padding: 10px 20px;
  }
  @include breakpoint(xlarge) {
    padding: 20px 20px;
  }


  &.hollow {
    border: solid 2px black;
  }

  @each $type, $color in $palette {
    &.#{$type} {
      &:not(.hollow) {
        background-color: $color;
        @if(lightness($color) > 62) {
          color: $black;
        } @else {
          color: $white;
        }
      }
      &.hollow {
        border-color: $color;
        color: $color;
      }
    }
  }

  *, *:before, *:after {
    color: inherit;
  }
}

button:not(.button) {
  background: none;
  border: none;
}