@use "../../../breakpoints" as *;
@import "../../../resources/scss/settings";
@import "../../../resources/scss/mixins";

.menu-button-wrapper {
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  padding-top: 17px;
  padding-left: 14px;
  padding-right: 14px;
  transition: all 250ms ease 0s;
  z-index: 2;

  @include breakpoint(large) {
    display: none;
  }

  > * {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    position: relative;
    overflow: hidden;
    height: 60px - 17px - 17px;

    .line {
      width: 100%;
      height: 3px;
      background: white;
      position: absolute;
      transition: all 250ms ease;
      left: 0;
    }

    &.hamburger {
      .line {
        background-color: $black;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
          transform-origin: center center;
          transform: translateY(-50%);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
    &.cross {
      .line {
        top: 50%;

        &:nth-child(1) {
          transform: translateY(-60px) translateX(-60px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateY(-60px) translateX(60px) rotate(-45deg);
        }
      }
    }
  }



  @include breakpoint(medium) {
    &:hover {
      background-color: map_get($foundation-palette, primary);

      > .hamburger > .line {
        background: $white;
      }
    }
  }

  &.menu-open {
    background-color: rgba(0,0,0,0);
    transition: all 250ms ease 250ms;

    > .hamburger {
      .line {
        &:nth-child(1) {
          transform: translateX(-100%);
        }
        &:nth-child(2) {
          transform: translateY(-50%) scaleX(0);
        }
        &:nth-child(3) {
          transform: translateX(100%);
        }
      }
    }

    > .cross {
      .line {
        &:nth-child(1) {
          transform: translateY(-50%) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }



  @include breakpoint(small only) {
    position: fixed;
    right: 10px;
    //left: calc(50vw - #{$top-bar-small-button-size} * 5.5);
    top: 10px;
    height: $top-bar-small-button-size;
    width: $top-bar-small-button-size;
    border-radius: 50%;
    background-color: $white;
    padding: calc(#{$top-bar-small-button-size} * 0.3) calc(#{$top-bar-small-button-size} * 0.25);

    &:before {
      content: "\00a0";
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }

    > * {
      height: 100%;

      .line {
        height: 2px;
        background-color: $black;
      }
    }

    &.menu-open {
      left: calc(100vw - 10px - 42px);
      transform: scale(1.5);

      > * {
        .line {
          background-color: $white;
        }
      }
    }

    @include ios-safari-only {
      > * {
        height: 70%;
      }
    }
  }
}