@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.top-bar {
  position: fixed;
  right: 0;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(2, map_get($top-bar-height, small));
  height: 0;

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, map_get($top-bar-height, medium));
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(1, map_get($top-bar-height, large));
  }

  > * {
    height: map_get($top-bar-height, small);

    @include breakpoint(medium) {
      height: map_get($top-bar-height, medium);
    }
    @include breakpoint(large) {
      height: map_get($top-bar-height, large);
    }
  }
}