@use "../../breakpoints" as *;
@import "../../../resources/scss/settings";

$variants: (
        'top-left',
        'top-right',
        'bottom-right',
        'bottom-left',
        'left',
        'right',
        'top',
        'bottom',
        'drop-left-top',
        'drop-left-bottom',
        'circle'
);

@mixin eps-figure-styling() {
  figure {
    margin: 0;
    padding: 0;
    height: 100vw;

    &.wp-block-embed.is-type-video {
      width: 100%;
      padding: 0;

      > * {
        width: inherit;
        height: inherit;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.mobile-half {
      @include breakpoint(small only) {
        height: 50vw;
      }
    }

    @include breakpoint(medium) {
      height: 25vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


    &.wp-block-image.is-style-with-text {
      height: auto;
      display: grid;

      @include breakpoint(small only) {
        height: auto;
      }

      @for $i from 1 through 2 {
        &.rows-#{$i} {
          grid-row: span #{$i};
        }
      }
      @for $i from 2 through 4 {
        &.columns-#{$i} {
          grid-column: span #{$i};

          @include breakpoint(medium) {
            @if ($i == 2) {
              grid-template-columns: repeat(2, 50%);
            } @else {
              &.rtl {
                grid-template-columns: 1fr calc(100% / #{$i} * #{$i - 1});
              }
              &:not(.rtl) {
                grid-template-columns: calc(100% / #{$i} * #{$i - 1}) 1fr;
              }
            }
          }
        }
      }

      img {
        height: 100vw;
        max-width: 100vw;

        @include breakpoint(medium) {
          max-height: calc(25vw);
        }
      }
      figcaption {
        .caption-inner {
          font-size: 12px;
          color: map_get($foundation-palette, secondary);
          padding: map_get($visible-grid-size, small);
          background: white;

          @include breakpoint(medium) {
            padding: 20px;
            transform: translateX(-10px) translateY(10px);
          }
          @include breakpoint(large) {
            transform: translateX(-20px) translateY(20px);
          }
          @include breakpoint(xxlarge) {
            font-size: 16px;
            padding: 40px;
            width: 85%;
            transform: translateX(-40px) translateY(40px);
          }
        }
      }
      &.rtl {
        @include breakpoint(medium) {
          > * {
            grid-row: 1 / 2;
          }
          >:nth-child(1) {
            grid-column: 2 / 3;
          }
          >:nth-child(2) {
            grid-column: 1 / 2;
          }
        }
        figcaption {
          .caption-inner {
            @include breakpoint(medium) {
              transform: translate(10px, 10px);
            }
            @include breakpoint(large) {
              transform: translateX(20px) translateY(20px);
            }
            @include breakpoint(xxlarge) {
              transform: translate(calc(100% / 85 * 15 + 40px), 40px);
            }
          }
        }
      }
    }

    @each $variant in $variants {
      &.wp-block-image.is-style-#{$variant} {
        img {
          @if ($variant == 'top-left') {
            border-top-left-radius: 100%;
          } @else if ($variant == 'top-right') {
            border-top-right-radius: 100%;
          } @else if ($variant == 'bottom-right') {
            border-bottom-right-radius: 100%;
          } @else if ($variant == 'bottom-left') {
            border-bottom-left-radius: 100%;
          } @else if ($variant == 'left') {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          } @else if ($variant == 'right') {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          } @else if ($variant == 'top') {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
          } @else if ($variant == 'bottom') {
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
          } @else if ($variant == 'drop-left-top') {
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
          } @else if ($variant == 'drop-left-bottom') {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 50%;
          } @else if ($variant == 'circle') {
            border-radius: 50%;
          }
        }
      }
    }
  }

  > .wp-block-squde-clean-eps-eps-double-column {
    //> figure.wp-block-embed.is-type-video {
    //  position: relative;
    //
    //  @include breakpoint(medium) {
    //    transform: translateX(-50%);
    //  }
    //}
    //@for $i from 1 through 4 {
    //  &.is-style-columns-#{$i} {
    //    > figure.wp-block-embed.is-type-video {
    //      @include breakpoint(medium) {
    //        @if ($i == 1) {
    //          left: calc(50%);
    //        } @else {
    //          left: 40%;
    //        }
    //        width: calc(25vw * #{$i});
    //      }
    //      @include breakpoint(large) {
    //        @if ($i == 1) {
    //          left: calc(50%);
    //        } @else {
    //          left: 30%;
    //        }
    //      }
    //    }
    //  }
    //}
  }
  > .wp-block-squde-clean-eps-eps-double-column:not(:has(> :nth-child(2))):has(> figure) {
    padding: 0 !important;
  }
}