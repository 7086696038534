@use "../../../breakpoints" as *;
@import "../../../resources/scss/settings";

.search-button-wrapper {
  background-color: white;
  transition: all 250ms ease;
  background-size: contain;
  display: grid;
  justify-items: center;
  align-items: center;

  @include breakpoint(medium down) {
    visibility: hidden;
  }


  &:not(.show) {
    opacity: 0;
  }

  span {
    height: 22px;
    width: 22px;
    grid-column: 1;
    grid-row: 1;
    mask-position: center center;
    mask-size: contain;
    background-color: $black;
    transition: inherit;
  }

  @include breakpoint(medium) {
    &:hover {
      background-color: map_get($foundation-palette, primary);

      span {
        background-color: $white;
      }
    }
  }
  @include breakpoint(small only) {
    $size: calc(#{map_get($visible-grid-size, small)} * 1.25);
    position: fixed;
    border-radius: 50%;
    top: 10px;
    height: $top-bar-small-button-size;
    width: $top-bar-small-button-size;
    right: calc(10px + #{$top-bar-small-button-size} * 1.2 * 2);
    //left: calc(50vw + #{$top-bar-small-button-size} * 3.5 - 5px);

    &:before {
      content: "\00a0";
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }
}