@use "../../breakpoints" as *;

@mixin eps-columns() {
  .wp-block-squde-clean-eps-eps-double-column {
    //padding: map_get($visible-grid-size, small);

    @for $i from 1 through 4 {
      &.is-style-columns-#{$i} {
        grid-column: span #{$i};
      }
    }

    &.is-style-columns-2, &.is-style-columns-3, &.is-style-columns-4 {
      display: grid;

      @include breakpoint(medium) {
        padding: 0 10%;
      }
      @include breakpoint(large) {
        padding: 0 20%;
      }

      > .wp-block-squde-clean-eps-eps-title {
        margin: 0 auto;
        padding: 0;
        align-self: center;
      }

      @for $i from 2 through 4 {
        &.is-style-columns-#{$i} {
          > .wp-block-squde-clean-eps-eps-title {
            max-width: calc(100vw / #{$i});
          }
        }
      }
    }
  }
}