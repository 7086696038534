// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

/// Font size attribute applied to `<html>` and `<body>`. We use 100% by default so the value is inherited from the user's browser settings.
/// @type Number
$global-font-size: 100% !default;

/// Global width of your site. Used by the grid to determine row width.
/// @type Number
$global-width: calc(5 * 320px) !default;

/// Default line height for all type. `$global-lineheight` is 24px while `$global-font-size` is 16px
/// @type Number
$global-lineheight: 1.5 !default;

/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
/// @type Map
$foundation-palette: (
        primary: #029891,
        primary-alt: #FFEC85,
        secondary: #EF7F22,
        secondary-alt: #60A8D8,
        tertiary: #D2D1E7,
        success: #BBF084,
        warning: #ffae00,
        alert: #EF4742,
) !default;

/// Color used for light gray UI items.
/// @type Color
$light-gray: #e6e6e6 !default;

/// Color used for medium gray UI items.
/// @type Color
$medium-gray: #cacaca !default;

/// Color used for dark gray UI items.
/// @type Color
$dark-gray: #8a8a8a !default;

/// Color used for black ui items.
/// @type Color
$black: #0B0F41 !default;
$real-black: #333333 !default;

/// Color used for white ui items.
/// @type Color
$white: #fefefe !default;

/// Background color of the body.
/// @type Color
$body-background: $white !default;

/// Text color of the body.
/// @type Color
$body-font-color: $black !default;

/// Font stack of the body.
/// @type List
$body-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !default;

/// Set to `true` to enable antialiased type, using the `-webkit-font-smoothing` and `-moz-osx-font-smoothing` CSS properties.
/// @type Boolean
$body-antialiased: true !default;

/// Global value used for margin on components.
/// @type Number
$global-margin: 1rem !default;

/// Global value used for padding on components.
/// @type Number
$global-padding: 1rem !default;

/// Global value used for positioning on components.
/// @type Number
$global-position: 1rem !default;

/// Global font weight used for normal type.
/// @type Keyword | Number
$global-weight-normal: normal !default;

/// Global font weight used for bold type.
/// @type Keyword | Number
$global-weight-bold: bold !default;

/// Global value used for all elements that have a border radius.
/// @type Number
$global-radius: 0 !default;

/// Global value used for all menu styles. Can be overwritten at individual menu component level.
/// @type Number
$global-menu-padding: 0.7rem 1rem !default;

/// Global value used for all menu styles. Nested margin for submenu.
$global-menu-nested-margin: 1rem !default;

/// Sets the text direction of the CSS. Can be either `ltr` or `rtl`.
/// @type Keyword
$global-text-direction: ltr !default;

/// Enables flexbox for components that support it.
/// @type Boolean
$global-flexbox: true !default;

/// Enabled responsive breakpoints for prototypes if applicable
/// @type Boolean
$global-prototype-breakpoints: false !default;

/// Button cursor's value, `auto` by default
/// @type Keyword
$global-button-cursor: auto !default;

@if not map-has-key($foundation-palette, primary) {
  @error 'In $foundation-palette, you must have a color named "primary".';
}

// Internal variables used for text direction
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// Internal variable that contains the flex justifying options
$-zf-flex-justify: -zf-flex-justify($global-text-direction);

/// Global tolerance for color pick contrast.
/// @type Number
$global-color-pick-contrast-tolerance: 0 !default;

$grid-column-count: 10;


$visible-grid-size: (
        small: calc((100vw / 379) * 62 / 2),
        medium: calc(100vw / 12),
        large: calc(100vw / 12),
        xlarge: calc(100vw / 12),
        xxlarge: calc(100vw / 12),
        full: 160px
);



$top-bar-height: (
        small: 60px,
        medium: 60px,
        large: 60px
);

$top-bar-small-button-size: 40px;