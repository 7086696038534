@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.copyright-wrapper {
  font-size: 12px;
  color: map_get($foundation-palette, secondary-alt);
  grid-column: 1 / -1;
  margin-bottom: 30px;

  @include breakpoint(medium) {
    text-align: center;
  }
}