@use "../../../breakpoints" as *;
@import "../../../resources/scss/settings";

.footer-menu-wrapper {
  grid-template-columns: repeat(2, 1fr);
  display: none;

  @include breakpoint(medium) {
    display: grid;
  }
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}