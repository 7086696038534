@use "../../breakpoints" as *;
@import "settings";

*, *:before, *:after {
  color: $black;
}

@each $type, $color in $foundation-palette {
  .color-#{$type} {
    color: $color;
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    small {
      display: block;
    }
  }
}

p {
  font-size: 12px;
  line-height: 1.6em;

  @include breakpoint(xlarge) {
    font-size: 16px;
  }
}