@use "../../breakpoints" as *;
@import "../../../resources/scss/settings";

@mixin eps-cta {
  .cta {
    margin: calc(100vw / 1920 * 50);
  }
  .wp-block-group {
    .cta {
      margin: 0;

      @include breakpoint(small only) {
        margin: map_get($visible-grid-size, small);
        width: calc(100% - #{map_get($visible-grid-size, small)} * 2);
      }
    }
  }
  .wp-block-squde-clean-eps-eps-double-column.is-style-columns-1 {
    .wp-block-group {
      .cta {
        @include breakpoint(small only) {
          margin: 10px 0;
          width: auto;
        }
      }
    }
  }
}