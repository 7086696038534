@import "../../../resources/scss/settings";

@mixin groups() {
  .wp-block-group {
    @include breakpoint(small only) {
      padding: map_get($visible-grid-size, small);
    }
  }
  .wp-block-squde-clean-eps-eps-double-column .wp-block-group:not(:has(p)) {
   padding: 0;
  }
}