@use "../../breakpoints" as *;
@import "../../resources/scss/settings";
@import "mixins/figure";
@import "mixins/groups";
@import "mixins/eps-columns";
@import "mixins/eps-headings";
@import "mixins/eps-text";
@import "mixins/eps-cta";
@import "mixins/eps-quote";
@import "mixins/inputs";

.cms-content-wrapper{
  display: grid;
  grid-template-columns: repeat(2, 50vw);

  @include breakpoint(medium) {
    //overflow-x: hidden; // Disabled because of stuttering on big screen
    grid-template-columns: repeat(4, 25vw);

    > * {
      min-height: 25vw;
    }
  }

  @include breakpoint(small only) {
    > *:not(.mobile-half) {
      grid-column-start: 1 !important;
      grid-column-end: 3 !important;
    }
    > .mobile-half {
      grid-column: span 1;
    }
  }



  @include eps-figure-styling;
  @include eps-text;
  @include eps-columns;
  @include eps-headings;
  @include eps-cta;
  @include eps-quote;
  @include groups;
  @include inputs;


  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-2):not(.is-style-columns-3):not(.is-style-columns-4),
  > .wp-block-group > .wp-block-group__inner-container {
    padding: map_get($visible-grid-size, small);

    @include breakpoint(medium) {
      padding: 20px;
    }

    @include breakpoint(xxlarge) {
      padding: 50px;
    }

    > *:nth-child(1) {
      margin-top: 0;
    }
    > *:nth-last-child(1) {
      margin-bottom: 0;
    }
    > .button {
      margin-left: 0;
      margin-right: 0;
    }
  }


  .arrow-right {
    @include breakpoint(medium only) {
      max-width: calc(25vw - 40px);
    }
  }
  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-1), > .action-bar-wrapper {
    .arrow-right {
      @include breakpoint(medium only) {
        max-width: none;
      }
    }
  }
  > .wp-block-squde-clean-eps-eps-double-column > .wp-block-group {
    &:not(:nth-child(1)):nth-last-child(1) {
      margin-bottom: 20px;
    }
  }

  .flex-group {
    display: flex;

    &.align-center {
      align-items: center;
      align-content: center;
    }
  }
}
.cms-content-container {
  @include eps-text;
  @include eps-headings;
}
