@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.route-wrapper {
  &:before {
    content: '\00a0';
    display: block;
    width: 100%;
    height: .0000001em;
  }
}