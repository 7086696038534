@use "../../breakpoints" as *;

.button {
  border: none;
  cursor: pointer;

  &[class*="arrow-"] {
    position: relative;
    font-size: 12px !important;
    font-weight: 600;

    @include breakpoint(medium) {
      font-size: 14px !important;
    }
    @include breakpoint(xlarge) {
      font-size: 16px !important;
    }


    &:before, &:after {
      font-family: "Open Sans", sans-serif;
      position: absolute;
      color: inherit;
    }

    &.arrow-right {
      padding-right: 50px;
      padding-left: 20px;

      @include breakpoint(small only) {
        text-align: left;
      }

      &:after {
        right: 20px;
        content: "→";
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }
    &.arrow-left {
      padding-right: 40px;
      padding-left: 80px;

      &:after {
        left: 0;
        content: "←";
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
      }
    }
  }
  //&[class*="arrow-"] {
  //  display: inline-grid;
  //  padding: 0 20px;
  //
  //  &.arrow-left, &.arrow-right {
  //    grid-template-columns: repeat(2, 1fr);
  //  }
  //  &.arrow-up, &.arrow-down {
  //    grid-template-rows: repeat(2, 1fr);
  //  }
  //  &.arrow-left {
  //    .squde-icon {
  //      grid-column: 1 / 3;
  //    }
  //  }
  //  &.arrow-top {
  //    .squde-icon {
  //      grid-row: 1 / 3;
  //    }
  //  }
  //
  //  .squde-icon {
  //    width: 100%;
  //    transform: scale(.7);
  //  }
  //}

  &.has-icon {
    position: relative;

    .squde-icon {
      $size: 20px;

      position: absolute;
      height: $size;
      width: $size;
      right: 20px;
      top: calc(50% - #{$size} / 2);
    }
  }
}
