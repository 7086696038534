@use "../../../breakpoints" as *;
@import "../../../resources/scss/settings";

.phone-button-wrapper {
  @include breakpoint(medium) {
    display: none;
  }

  background: map_get($foundation-palette, primary);
  transition: opacity 250ms ease;
  background-size: contain;
  display: grid;
  justify-items: center;
  align-items: center;
  position: fixed;
  border-radius: 50%;
  top: 10px;
  //right: 10px;
  height: $top-bar-small-button-size;
  width: $top-bar-small-button-size;
  right: calc(10px + #{$top-bar-small-button-size} * 1.2);
  //left: calc(50vw + #{$top-bar-small-button-size} * 4.5);

  &:before {
    content: "\00a0";
    position: absolute;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  &:not(.show) {
    opacity: 0;
  }
  span {
    height: 18px;
    width: 18px;
    grid-column: 1;
    grid-row: 1;
  }
}