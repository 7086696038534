@use "../../../breakpoints" as *;
@import "../../../resources/scss/settings";

@mixin eps-text() {
  p {
    color: #5D5D5D;

    b, strong {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
  > p {
    margin: 0;
    padding: map_get($visible-grid-size, small);
    background: lighten(map_get($foundation-palette, tertiary), 10%);

    &.is-style-yellow {
      background: var(--color-primary-alt);
    }
    &.is-style-white {
      background: var(--color-white);
    }

    @include breakpoint(medium) {
      padding: 20px;
    }

    @include breakpoint(xxlarge) {
      padding: 50px;
    }
  }
  > p,
  > .wp-block-squde-clean-eps-eps-double-column > p {
    line-height: 1.6em;
    font-size: 12px;

    @include breakpoint(xlarge) {
      font-size: 16px;
    }
  }
  > .wp-block-squde-clean-eps-eps-double-column > p {
    margin: 1em 0;
  }
  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-1) > p,
  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-1) > h2,
  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-1) > h3,
  > .wp-block-squde-clean-eps-eps-double-column:not(.is-style-columns-1) > h4 {
    padding: 0 map_get($visible-grid-size, small);


    @include breakpoint(medium) {
      padding: 0;
    }
  }
}