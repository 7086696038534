// loader contains 3 spans with a dot in each, they should be centered and continously animated


*:has(> .loader) {
  position: relative;
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms ease;

  &:not(.loading) {
    pointer-events: none;
    transform: scale(0);
  }

  div {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-primary);
    margin: 0 5px;
    animation: loader 1s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    @keyframes loader {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }
}