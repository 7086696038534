@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.sidebar-wrapper {
  background-color: map_get($foundation-palette, primary-alt);
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  transition: transform 500ms ease;
  width: 100vw;

  @include breakpoint(medium) {
    width: 45vw;
    max-width: 375px;
  }
  @include breakpoint(large) {
    max-width: 486px;
  }

  &:not(.show) {
    transform: translateX(100%);
  }

  .close-button {
    position: absolute;
    height: 80px;
    width: 80px;
    right: 0;
    top: 0;
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .sidebar-contents {
    margin-top: 80px;
    padding: 0 40px;
    overflow: scroll;
    overflow-x: hidden;
    font-size: 12px;
    height: calc(100vh - 80px);

    @include breakpoint(small only) {
      height: calc(100vh - 150px);
    }

    p {
      color: #5D5D5D;
    }

    > *:nth-child(1) {
      margin-top: 0;
    }

    h1 {
      margin-top: 0;
      font-weight: 300;
      font-size: 30px;
    }
    h2 {
      font-weight: normal;
      font-size: 200%;
    }
    h4 {
      font-weight: 300;
      font-size: 24px;
    }
  }
}
/**
Disable lastpass, because it breaks everything
 */
div[id^=__lpform_] {
  display: none !important;
}
