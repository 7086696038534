@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.logo-wrapper {
  background-color: white;
  transition: transform 500ms ease;

  &:not(.with-text-payoff) {
    overflow: hidden;
  }
  &.with-small-payoff {
    transform: translateY(50px);
  }

  &:not(.inline) {
    border-radius: 50% 0 50% 50%;
    left: 50%;
    top: 0;
    z-index: 10;
    transform: translateY(-25%) translateX(-50%);
    position: fixed;

    @include breakpoint(small only) {
      transform: translateY(-25%) translateX(calc(#{map_get($visible-grid-size, small)} * 5 * -1));
    }
    @include breakpoint(medium only) {
      transform: translateY(-25%) translateX(calc(#{map_get($visible-grid-size, medium)} * 5 * -1));
    }

    @include breakpoint(large) {
      position: absolute;
    }

    .logo {
      .logo-content {
        bottom: 10%;
        right: 10%;
      }
    }
  }

  &.payoff-on-right {
    transform-origin: top left;
    transform: scale(calc(1 / 320 * 60));
    overflow: unset;
    display: grid;
    grid-template-columns: 320px 640px;

    .logo {
      transform-origin: top left;
      transform: scale(calc(1 / 45 * 60));



      @include breakpoint(large) {
        transform: scale(calc(1 / 35 * 60));
      }
      @include breakpoint(xlarge only) {
        transform: scale(calc(1 / 40 * 60));
      }
      @include breakpoint(xxlarge) {
        transform: scale(calc(1 / 45 * 60));
      }

      @media screen and (min-width: $global-width) {
        transform: none;
      }

      .logo-content {
        right: 6%;
        bottom: 12%;
        grid-template-rows: 1fr 0;
        transform: scale(calc(1 / 36 * 60));
        //transform-origin: bottom right;

        .subtitle {
          display: none;
        }
      }
    }
    .payoff-wrapper {
      transform-origin: top center;
      align-self: center;
      transform: translateX(80%) translateY(-50%) scale(1.8);

      @include breakpoint(large) {
        transform: translateX(80%) translateY(0%) scale(1.8);
      }
      @include breakpoint(xlarge only) {
        transform: translateX(80%) translateY(0%) scale(1.8);
      }

      @media screen and (min-width: $global-width) {
        transform: translateX(80%) translateY(-10%) scale(1.8);

        transform-origin: center center;
      }

      img {
        clip-path: inset(0 100% 0 0);
        transition: clip-path 1s ease-out .5s;
      }

      &.show {
        img {
          clip-path: inset(0 0 0 0);
        }

      }
    }
  }

  @each $screenSize, $gridSize in $visible-grid-size {
    @if ($screenSize == 'full') {
      @media screen and (min-width: $global-width) {
        height: $gridSize * 2;
        width: $gridSize * 2;

        &:not(.inline) {
          margin-left: $gridSize * 2 * 2 * -1;
        }
      }
    } @else {
      @include breakpoint($screenSize only) {
        height: calc(#{$gridSize} * 2);
        width: calc(#{$gridSize} * 2);

        @if ($screenSize == 'large' or $screenSize == 'xlarge' or $screenSize == 'xxlarge') {
          &:not(.inline) {
            margin-left: calc(#{$gridSize} * 2 * 2 * -1);
          }
        } @else if ($screenSize == 'small') {
          height: calc(#{$gridSize} * 5);
          width: calc(#{$gridSize} * 5);
        } @else if ($screenSize == 'medium') {
          height: calc(#{$gridSize} * 4);
          width: calc(#{$gridSize} * 4);
        }

      }
    }
  }

  .small-payoff-wrapper {
    width: 50%;
    margin-left: 44%;
    margin-top: 20%;
  }
  .text-payoff {
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    position: relative;
    padding-top: 2em;

    @each $screenSize, $gridSize in $visible-grid-size {
      @if ($screenSize == 'full') {
        @media screen and (min-width: $global-width) {
          transform: translateX(calc(#{$gridSize} * 0.2 * 4.5));
        }
      } @else {
        @include breakpoint($screenSize only) {
          @if ($screenSize == 'small') {
            transform: translateX(calc(#{$gridSize} * 2.5 * 0.2 * 4.5));
          } @else if ($screenSize == 'medium') {
            transform: translateX(calc(#{$gridSize} * 2 * 0.2 * 4.5));
          } @else {
            transform: translateX(calc(#{$gridSize} * 0.2 * 4.5));
          }
        }
      }
    }

    &:before {
      position: absolute;
      //width: 20px;
      height: 2px;
      background: map_get($foundation-palette, secondary);
      content: "\00a0";
      transform: translateY(-1em);

      @each $screenSize, $gridSize in $visible-grid-size {
        @if ($screenSize == 'full') {
          @media screen and (min-width: $global-width) {
            width: calc(#{$gridSize} * 0.2);
          }
        } @else {
          @include breakpoint($screenSize only) {
            @if ($screenSize == 'small') {
              width: calc(#{$gridSize} * 2.5 * 0.2);
            } @else if ($screenSize == 'medium') {
              width: calc(#{$gridSize} * 2 * 0.2);
            } @else {
              width: calc(#{$gridSize} * 0.2);
            }
          }
        }
      }
    }
  }

  .logo {
    position: relative;
    height: 100%;
    width: 100%;


    .logo-content {
      position: absolute;
      right: 15%;
      bottom: 18%;
      height: 50%;
      width: 60%;
      display: grid;
      grid-template-rows: 1fr .5fr;

      .letters-wrapper {
        display: grid;

        @each $screenSize, $gridSize in $visible-grid-size {
          @if ($screenSize == 'full') {
            @media screen and (min-width: $global-width) {
              grid-template-columns: repeat(6, calc(#{$gridSize} * 0.2));
              grid-template-rows: repeat(3, calc(#{$gridSize} * 0.2));
            }
          } @else {
            @include breakpoint($screenSize only) {

              @if ($screenSize == 'small') {
                grid-template-columns: repeat(6, calc(#{$gridSize} * 2.5 * 0.2));
                grid-template-rows: repeat(3, calc(#{$gridSize} * 2.5 * 0.2));
              } @else if ($screenSize == 'medium') {
                grid-template-columns: repeat(6, calc(#{$gridSize} * 2 * 0.2));
                grid-template-rows: repeat(3, calc(#{$gridSize}  * 2* 0.2));
              } @else {
                grid-template-columns: repeat(6, calc(#{$gridSize} * 0.2));
                grid-template-rows: repeat(3, calc(#{$gridSize} * 0.2));
              }
            }
          }
        }


        > span {
          display: block;
          overflow: hidden;
          position: relative;

          &:before {
            content: "\00a0";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }

          .lines-wrapper {
            position: absolute;
            height: 100%;
            transform-origin: top;
            transform: translateY(-10%) rotate(45deg) translateX(-400%);
            width: 200%;
            animation-name: draw-logo-lines-in;
            animation-delay: .5s;
            animation-iteration-count: 1;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease;
            animation-direction: normal;

            img {
              height: 40px;
              transform: translateY(10%);

              @each $screenSize, $gridSize in $visible-grid-size {
                @if ($screenSize == 'full') {
                  @media screen and (min-width: $global-width) {
                    //height: calc(#{$gridSize} * 0.2 * 0.12);
                    height: 40px;
                  }
                } @else {
                  $large-width: map_get($visible-grid-size, full);
                  @include breakpoint($screenSize only) {
                    @if ($screenSize == 'small') {
                      transform: translateY(0);
                      height: calc(.72 * #{$gridSize});
                    } @else if ($screenSize == 'medium') {
                      height: calc(.50 * #{$gridSize});
                    } @else {
                      height: calc(.26 * #{$gridSize});
                    }
                  }
                }
              }
            }

            //.line {
            //  position: absolute;
            //  width: 100%;
            //  background: white;
            //
            //  @each $screenSize, $gridSize in $visible-grid-size {
            //    @if ($screenSize == 'full') {
            //      @media screen and (min-width: $global-width) {
            //        //height: calc(#{$gridSize} * 0.2 * 0.12);
            //        height: 4.5px;
            //      }
            //    } @else {
            //      @include breakpoint($screenSize only) {
            //        height: calc(#{$gridSize} * 0.2 * 0.12);
            //
            //        @if ($screenSize == 'small') {
            //          height: calc(#{$gridSize} * 3 * 0.2 * 0.12);
            //        }
            //      }
            //    }
            //  }
            //
            //  &:before {
            //    content: "\00a0";
            //  }
            //
            //  @for $i from 0 through 5 {
            //    &:nth-child(#{$i + 1}) {
            //      transform:  scaleX(2) translateY(calc(100% * #{$i * 2}));
            //    }
            //  }
            //}

            @keyframes draw-logo-lines-in {
              from {
                transform: translateY(-10%) rotate(45deg) translateX(-400%);
              }
              to {
                transform: translateY(-10%) rotate(45deg);
              }
            }
          }

          @for $i from 1 through (3 * 6) {
            &:nth-child(#{$i}) {
              $column: $i;
              $row: 1;
              @while($column > 6) {
                $column: $column - 6;
                $row: $row + 1;
              }
              grid-column-start: $column;
              grid-row-start: $row;
            }
          }
          &:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: 4;
            @each $screenSize, $gridSize in $visible-grid-size {
              @if ($screenSize == 'full') {
                @media screen and (min-width: $global-width) {
                  border-bottom-left-radius: calc(#{$gridSize} * 0.2);
                  border-top-left-radius: calc(#{$gridSize} * 0.2);
                }
              } @else {
                @include breakpoint($screenSize only) {
                  border-bottom-left-radius: calc(#{$gridSize} * 0.2);
                  border-top-left-radius: calc(#{$gridSize} * 0.2);

                  @if ($screenSize == 'small') {
                    border-bottom-left-radius: calc(#{$gridSize} * 3 * 0.2);
                    border-top-left-radius: calc(#{$gridSize} * 3 * 0.2);
                  } @else if ($screenSize == 'medium') {
                    border-bottom-left-radius: calc(#{$gridSize} * 2 * 0.2);
                    border-top-left-radius: calc(#{$gridSize} * 2 * 0.2);
                  } @else {
                    border-bottom-left-radius: calc(#{$gridSize} * 0.2);
                    border-top-left-radius: calc(#{$gridSize} * 0.2);
                  }
                }
              }
            }

            .lines-wrapper {
              margin-top: -2px;
            }

            &:before {
              transform-origin: top left;
              transform: translateY(-110%);
              animation: boring-fade-in-from-top .5s 1 ease-out .1s forwards;

              background: #1A0946;
            }
          }
          &:nth-child(2) {
            transform: scale(0) translate(-100%, -100%);
            animation: bubble-one .25s 1 ease-out .5s forwards;

            &:before {
              border-radius: 50%;
              transform: scale(.8);
              transform-origin: top center;
              background: #970060;
            }
          }
          &:nth-child(3) {
            transform: scale(0);
            animation: splat .25s 1 ease-out 1s forwards;
            border-top-left-radius: 75%;

            &:before {
              border-radius: 50%;
              transform:  translate(-50%, -50%) scale(1.8);
              background: #E52A25;
            }
          }
          &:nth-child(4) {
            border-top-right-radius: 100%;

            &:before {
              transform-origin: top left;
              transform: translateX(-110%);
              animation: boring-fade-in .25s 1 ease-out 1.1s forwards;
              background: #1A0946;
            }
          }
          &:nth-child(5) {
            margin-left: 5%;
            border-top-left-radius: 100%;

            &:before {
              transform-origin: top right;
              transform: translateX(110%);
              animation: boring-fade-in-from-right .25s 1 ease-out 1.25s forwards;
              background: #039891;
            }
          }
          &:nth-child(6) {
            transform: scale(0) translate(100%, -100%);
            animation: bubble-three .35s 1 ease-out 1.65s forwards;

            &:before {
              border-radius: 50%;
              transform: scale(.9);
              background: #8FBF1F;
            }
          }
          &:nth-child(7) {
            opacity: 0;
          }
          &:nth-child(8) {
            transform: scale(0) translate(100%, 100%);
            animation: bubble-two .35s 1 ease-out .7s forwards;

            &:before {
              border-radius: 50%;
              transform-origin: bottom left;
              transform: scale(.6) translate(10%, -10%);
              background: #1A0946;
            }
          }
          &:nth-child(9) {
            transform-origin: top left;
            grid-row-start: 2;
            grid-row-end: 4;

            &:before {
              background: #EC6F00;
              transform: translateY(-105%);
              animation: tear-drop-down 1s 1 ease-out 1.25s forwards;


              @each $screenSize, $gridSize in $visible-grid-size {
                @if ($screenSize == 'full') {
                  @media screen and (min-width: $global-width) {
                    border-bottom-right-radius: calc(#{$gridSize} * 0.2 * .5);
                    border-bottom-left-radius: calc(#{$gridSize} * 0.2 * .5);
                  }
                } @else {
                  @include breakpoint($screenSize only) {

                    @if ($screenSize == 'small') {
                      border-bottom-right-radius: calc(#{$gridSize} * 3 * 0.2 * .5);
                      border-bottom-left-radius: calc(#{$gridSize} * 3 * 0.2 * .5);
                    } @else if ($screenSize == 'medium') {
                      border-bottom-right-radius: calc(#{$gridSize} * 2 * 0.2 * .5);
                      border-bottom-left-radius: calc(#{$gridSize} * 2 * 0.2 * .5);
                    } @else {
                      border-bottom-right-radius: calc(#{$gridSize} * 0.2 * .5);
                      border-bottom-left-radius: calc(#{$gridSize} * 0.2 * .5);
                    }
                  }
                }
              }
            }
          }
          &:nth-child(10) {
            &:before {
              transform: translate(-105%, -105%);
              animation: fluid-in-one .35s 1 ease-out 1s forwards;
              clip-path: circle(100.0% at 0 0);
              background: #F8B100;
            }

            .lines-wrapper {
              margin-top: -1px;
            }
          }
          &:nth-child(11) {
            margin-left: 5%;
            border-bottom-left-radius: 100%;

            &:before {
              background: #1A0946;
              transform-origin: top left;
              transform: translateX(-110%);
              animation: boring-fade-in .25s 1 ease-out 1.5s forwards;
            }
          }
          &:nth-child(12) {
            border-top-right-radius: 100%;

            &:before {
              background: #1A0946;
              transform-origin: top left;
              transform: translateX(-110%);
              animation: boring-fade-in .25s 1 ease-out 1.75s forwards;
            }
          }
          &:nth-child(13) {
            opacity: 0;
          }
          &:nth-child(14) {
            transform: scale(0);
            transform-origin: bottom left;
            animation: drop-anim .2s 1 ease-out .7s forwards;

            &:before {
              border-radius: 50% 50% 50% 0;
              transform: scale(.8);
              transform-origin: bottom center;
              background: #196FB6;
            }
          }
          &:nth-child(15) {
            opacity: 0;
          }
          &:nth-child(16) {
            opacity: 0;
          }
          &:nth-child(17) {
            transform: scale(0);
            transform-origin: bottom right;
            animation: drop-anim .2s 1 ease-out 2.1s forwards;


            &:before {
              background: #1A0946;
              border-radius: 50% 50% 0 50%;
              transform: scale(.8);
              transform-origin: bottom center;
            }
          }
          &:nth-child(18) {
            clip-path: circle(100.0% at 0 0);

            &:before {
              background: #3D2482;
              transform-origin: top left;
              transform: translateY(-110%);
              animation: boring-fade-in-from-top .25s 1 ease-out 2s forwards;
            }

            .lines-wrapper {
              margin-top: 5px;
            }
          }

          @keyframes boring-fade-in {
            from {
              transform: translateX(-110%);
            }
            to {
              transform: translateX(0);
            }
          }
          @keyframes boring-fade-in-from-right {
            from {
              transform: translateX(110%);
            }
            to {
              transform: translateX(0);
            }
          }
          @keyframes boring-fade-in-from-top {
            from {
              transform: translateY(-110%);
            }
            to {
              transform: translateY(0);
            }
          }
          @keyframes splat {
            0% {
              transform: scale(0);
            }
            50% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes bubble-one {
            0% {
              transform: scale(0) translate(-100%, -100%);
            }
            50% {
              transform: scale(1.2) translateX(10%);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes bubble-two {
            0% {
              transform: scale(0) translate(100%, 100%);
            }
            50% {
              transform: scale(1.2) translateX(10%);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes bubble-three {
            0% {
              transform: scale(0) translate(100%, -100%);
            }
            50% {
              transform: scale(1.2) translateX(-10%);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes drop-anim {
            0% {
              transform: scale(0);
            }
            50% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes tear-drop-down {
            0% {
              transform: translateY(-105%);
            }
            100% {
              transform: translate(0);
            }
          }
          @keyframes fluid-in-one {
            from {
              transform: translate(-105%, -105%);
            }
            to {
              transform: translate(0, 0);
            }
          }
        }

        //noinspection ALL
        > span {
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        }
      }

      .subtitle {
        @each $screenSize, $gridSize in $visible-grid-size {
          @if ($screenSize == 'full') {
            @media screen and (min-width: $global-width) {
              line-height: calc(#{$gridSize} * 0.1375);
              padding-left: calc(#{$gridSize} * 0.2 * 2);
            }
          } @else {
            @include breakpoint($screenSize only) {
              line-height: calc(#{$gridSize} * 0.1375);
              padding-left: calc(#{$gridSize} * 0.2 * 2);

              @if ($screenSize == 'small') {
                line-height: calc(#{$gridSize} * 3 * 0.1375);
                padding-left: calc(#{$gridSize} * 3 * 0.2 * 2);
              }
            }
          }
        }

        span {
          display: block;
          font-size: calc(100vw * 0.033);
          font-weight: bold;
          color: #1A0946;

          @include breakpoint(medium) {
            font-size: calc(100vw * 0.013);
          }

          @include breakpoint(large) {
            font-size: calc(100vw * 0.012);
          }
          @media screen and (min-width: $global-width) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  @for $i from 1 through 10 {
    &[data-delay="#{$i * 500}"] {
      .logo {
        .logo-content {
          .letters-wrapper {
            > span {
              .lines-wrapper {
                animation-delay: calc(.5s + #{$i * 500ms});
              }
              &:nth-child(1) {
                &:before {
                  animation-delay: calc(.1s + #{$i * 500ms});
                }
              }

              &:nth-child(2) {
                animation-delay: calc(.5s + #{$i * 500ms});
              }

              &:nth-child(3) {
                animation-delay: calc(1s + #{$i * 500ms});
              }

              &:nth-child(4) {
                &:before {
                  animation-delay: calc(1.1s + #{$i * 500ms});
                }
              }

              &:nth-child(5) {
                &:before {
                  animation-delay: calc(1.25s + #{$i * 500ms});
                }
              }

              &:nth-child(6) {
                animation-delay: calc(1.65s + #{$i * 500ms});
              }

              &:nth-child(8) {
                animation-delay: calc(.7s + #{$i * 500ms});
              }

              &:nth-child(9) {
                &:before {
                  animation-delay: calc(1.25s + #{$i * 500ms});
                }
              }

              &:nth-child(10) {
                &:before {
                  animation-delay: calc(1s + #{$i * 500ms});
                }
              }

              &:nth-child(11) {
                &:before {
                  animation-delay: calc(1.5s + #{$i * 500ms});
                }
              }

              &:nth-child(12) {
                &:before {
                  animation-delay: calc(1.75s + #{$i * 500ms});
                }
              }

              &:nth-child(14) {
                animation-delay: calc(.7s + #{$i * 500ms});
              }

              &:nth-child(17) {
                animation-delay: calc(2.1s + #{$i * 500ms});
              }

              &:nth-child(18) {
                &:before {
                  animation-delay: calc(2s + #{$i * 500ms});
                }
              }
            }
          }
        }
      }
    }
  }

  .payoff-wrapper {
    height: 50px;
  }

  &:not(.animated) {
    *, *:before, *:after {
      animation-duration: 0s !important;
      animation-delay: 0s !important;
    }
  }
}


//.app-wrapper:not(.done) .logo-wrapper {
//  transform: translateY(-100%) translateX(-50%);
//
//  *, *:before, *:after {
//    animation: none !important;
//  }
//}