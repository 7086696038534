@use "../../breakpoints" as *;
@import "../../resources/scss/settings";

.newsletter-subscribe-wrapper {
  --newsletter-margin: 20px;
  --newsletter-button-size: 60px;

  position: fixed;
  bottom: var(--newsletter-margin);
  right: var(--newsletter-margin);
  z-index: 99;
  background: map_get($foundation-palette, success);
  padding: 0 calc(var(--newsletter-button-size) / 60 * 16);
  transition: all 250ms ease;
  border-right: none;
  border-radius: calc(var(--newsletter-button-size) / 2);
  border-bottom: none;
  transform: translateY(0);
  overflow: hidden;
  min-width: var(--newsletter-button-size);
  max-height: 40vh;

  &:not(.open) {
    max-height: var(--newsletter-button-size);
  }

  @include breakpoint(small only) {
    --newsletter-margin: 10px;


    transform-origin: bottom right;
    transform: scale(0.8);
    //bottom: calc(var(--newsletter-margin) * 0.5);
    //right: calc(var(--newsletter-margin) * 0.5);

    //transform: translateY(calc(100% - 56px)) translateX(calc(100% - 56px));
    //--newsletter-button-size: 45px;
    //
    //&:not(.open) {
    //  //background: none;
    //  //border-color: transparent;
    //
    //  &:before {
    //    background: map_get($foundation-palette, success);
    //    border: inherit;
    //    border-color: white;
    //    content: "\00a0";
    //    //border-radius: 50%;
    //    width: var(--newsletter-button-size);
    //    height: var(--newsletter-button-size);
    //    //width: 45px;
    //    //height: 45px;
    //    position: absolute;
    //    left: 2.5px;
    //    top: 2px;
    //  }
    //
    //  .newsletter-subscribe-label-wrapper {
    //    transform: translateY(2px);
    //
    //    .newsletter-subscribe-label-inner {
    //      span {
    //        opacity: 0;
    //      }
    //    }
    //  }
    //}
  }

  &.init {
    transform: translateY(calc(100% + 20px));
  }

  .newsletter-subscribe-label-wrapper {
    position: relative;
    cursor: pointer;
    height: var(--newsletter-button-size);
    display: flex;
    align-items: center;

    .newsletter-subscribe-label {

      .newsletter-subscribe-label-inner {
        margin: 0;
        display: flex;
        font-size: 14px;
        font-weight: bold;

        img {
          filter: brightness(0.5);
          margin: 0 4px;
        }
        .changing-titles {
          margin-top: -2px;
        }
      }
    }
  }
  .newsletter-subscribe-form {
    width: 400px;
    max-width: 0;
    pointer-events: none;
    opacity: 0;
    transition: all 500ms ease;
    //overflow: hidden;
    //display: none;

    button {
      left: 100%;
      transform: translateX(-100%);
      line-height: 10px;
    }
    p:nth-child(1) {
      margin-top: 0;
    }
    p.error {
      font-size: 80%;
      color: red;
    }
  }

  &.open {
    transform: translateY(0);

    .newsletter-subscribe-form {
      pointer-events: visible;
      max-width: calc(100vw - (var(--newsletter-margin) * 5));
      opacity: 1;
      padding-bottom: 8px;

      * {
        white-space: nowrap;
      }
    }
  }
}
