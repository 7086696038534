.changing-titles {
  position: relative;
  width: 0;
  transition: width 250ms ease;
  //overflow-x: hidden;

  span {
    display: block;
    position: absolute;
    white-space: nowrap;
    transition: opacity 250ms ease;
    opacity: 0;
    padding: 0 4px;
  }

  @for $i from 1 through 10 {
    &.show.active-#{$i - 1} {
      span:nth-child(#{$i}) {
        opacity: 1;
      }
    }
  }
}