@import "../../../resources/scss/settings";

@mixin inputs() {
  .field:not(.checkbox) {
    .field-content {
      display: grid;

      > :nth-last-child(1) {
        grid-row: 1 / 2;
        padding: 0 10px;
        transform: translateY(calc(49px - 70%));

        @include breakpoint(medium) {
          padding: 0 20px;
          transform: translateY(calc(62px - 90%));
        }
      }

      > input:not([type="button"]):not([type="submit"]) {
        background-color: $white !important;
        line-height: 49px;
        border: none;
        padding: 0 10px;

        @include breakpoint(medium) {
          padding: 0 20px;
          line-height: 62px;
        }

        &:focus + label, &:not([value=""]):not([data-value=""]) + label {
          padding: 0 10px;
          transform: translateY(0) !important;

          @include breakpoint(medium) {
            padding: 0 20px !important;
          }
        }
      }
    }


    &:before {
      top: 47.5px;
    }
  }
}