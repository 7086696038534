@use "../../breakpoints" as *;
@use "../../resources/scss/settings" as *;

.action-bar-wrapper {
  display: grid;
  grid-column: span 4;
  position: relative;
  padding: 50px 0;
  min-height: 0 !important;

  > * {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  .background-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: calc(100% + 50px * 2);
    margin-top: -50px;
    overflow: hidden;
    z-index: -1;
    display: grid;
    background: #FFEC85;


    > * {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top left;
    }
  }

  .contents-wrapper {
    align-self: center;
    display: grid;

    @include breakpoint(medium) {
    }
    @include breakpoint(medium only) {
      grid-template-columns: repeat(2, 50%);

      > :not(:nth-child(1)) {
        grid-column-start: 2;
      }
    }
    @include breakpoint(large) {
      grid-template-columns: 25% 50% 25%;
      justify-items: center;
      align-items: center;
    }

    .action-content-col {
      @each $screenSize, $gridSize in $visible-grid-size {
        @if ($screenSize == 'full') {
          @media screen and (min-width: $global-width) {
            padding: 0 calc(#{$gridSize} / 4);
          }
        } @else {
          @include breakpoint($screenSize only) {
            @if ($screenSize == 'small' or $screenSize == 'medium') {
              padding: 0 calc(#{$gridSize} / 2);
            } @else {
              padding: 0 calc(#{$gridSize} / 4);
            }
          }
        }
      }


      @include breakpoint(large) {
        &.form-wrapper {
          justify-self: normal;

          .action-content-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20%;
          }
        }
      }
      .action-content-container {
        width: 100%;

        > * {
          margin: 0;
          width: 100%;
        }

        h1 {
          font-weight: normal;

          @include breakpoint(small only) {
            margin-bottom: 20px;
          }
        }

        input {
          background: none;
          border: none;
          font-size: 18px;
          border-bottom: solid 1px $black;
          line-height: 36px;
          font-family: inherit;
          outline: none !important;

          @include breakpoint(medium down) {
            margin-bottom: 20px;
          }
        }

        .button {
          margin: 0;

          @include breakpoint(medium down) {
            margin-top: 20px;
          }
        }
      }
    }

  }
}
